@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @layer responsive {
        body{
            font-family: Poppins, sans-serif;
        }

        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        .yes_no > div > input:checked ~ label{
            background: #02796b !important;
            color: #fff;
            font-weight: bold;
        }

        .swal2-container {
            z-index: 20000 !important;
        }
    }
}

@layer components {
    .no-image-bg-primary {
        @apply bg-[#02796b];
    }

    .no-image-bg-secondary {
        @apply bg-[#00796b];
    }

    .no-image-bg-thirdy {
        @apply bg-[#00796b];
    }

    .no-image-text-primary {
        @apply text-[#02796b];
    }

    .no-image-text-secondary {
        @apply text-[#00796b];
    }

    .no-image-text-thirdy {
        @apply text-[#00796b];
    }

    .image-text-secondary{
        @apply text-[#8d3d3d]
    }

    .image-bg-primary{
        @apply bg-[#8d3d3d]
    }

    .image-text-primary{
        @apply text-[#8d3d3d]
    }

    .image-text-secondary{
        @apply text-[#8d3d3d]
    }

    .image-text-thirdy{
        @apply text-[#f2f0ed]
    }

    .image-bg-thirdy{
        @apply bg-[#f2f0ed]
    }

    .italian-bg-primary{
        @apply bg-[#9a9a9a]
    }

    .italian-text-primary{
        @apply text-[#9a9a9a]
    }

    .italian-bg-secondary{
        @apply bg-[#152553]
    }

    .italian-text-secondary{
        @apply text-[#152553]
    }

    .italian-menu-title {
        text-align: center;
        overflow: hidden;
        padding: 2px;
        font-size: 30px;
    }

    .italian-menu-title span {
        display: inline-block;
        position: relative;
        padding: 20px 30px;
    }

    .italian-menu-title span:before,
    .italian-menu-title span:after {
        content: '';
        display: block;
        position: absolute;
        width: 999px;
        border-bottom: 1px solid #444;
        border-top: 2px solid #444;
        height: 4px;
        top: 50%;
        margin-top: -3px;
    }

    .italian-menu-title span:before {
        right: 100%;
    }
    .italian-menu-title span:after {
        left: 100%;
    }
}
